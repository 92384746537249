define(['app'], function(app) {

  const collapsibleBanner = function() {
    const component = {
      collapseBtn: null,
      element: null
    };

    const selectors = {
      collapseButton: '.collapse-btn'
    };

    const _init = function(element) {
      component.element = element;
      component.collapseBtn = element.querySelector(selectors.collapseButton);
      component.bind();
      return component;
    };

    const _bind = function() {
      component.collapseBtn.addEventListener('click', component.buttonClicked);
    };

    const _buttonClicked = function() {
      if (component.element.classList.contains('collapsed')) {
        component.element.classList.remove('collapsed');
        component.element.classList.add('expanded');
      }
      else{
        component.element.classList.remove('expanded');
        component.element.classList.add('collapsed');
      }
    };

    component.init = _init;
    component.bind = _bind;
    component.buttonClicked = _buttonClicked;
    return component;
  };

  return collapsibleBanner;
});
